/* @define Home */
.Home {
  padding-top: 70px; }
  .Home-topSection {
    background: url("./background_top.png");
    width: 100%;
    color: white; }
    .Home-topSection-topImage {
      padding-top: 50px;
      width: 60%;
      height: auto;
      margin-left: auto;
      margin-right: auto; }
      .Home-topSection-topImage h1 {
        font-size: 45px; }
      .Home-topSection-topImage img {
        padding: 50px 0px 50px 0px;
        width: 100%;
        margin-left: auto;
        margin-right: auto; }
      .Home-topSection-topImage p {
        color: #d0d4d8; }
  .Home-middleSection {
    background: white;
    padding-top: 60px;
    color: #434343;
    padding-bottom: 75px; }
    .Home-middleSection-about {
      width: 60%;
      margin: auto;
      font-size: 25px;
      line-height: 30px; }
      .Home-middleSection-about-lineupBtn {
        margin: auto;
        width: 20%;
        text-align: center;
        background-color: #f5fac7;
        padding: 10px;
        border-radius: 5px; }
        .Home-middleSection-about-lineupBtn a {
          text-decoration: none;
          color: #434343;
          width: 100%; }
  .Home-moneyBorder {
    background: url("./money_border.png");
    width: 100%;
    height: 300px; }
  .Home-testimonials {
    padding-top: 100px;
    width: 80%;
    margin: auto;
    padding-bottom: 100px; }
    .Home-testimonials-caleb {
      display: table-cell; }
      .Home-testimonials-caleb-pic {
        display: table-cell;
        width: 30%; }
        .Home-testimonials-caleb-pic img {
          border: 2px black;
          height: auto;
          width: 70%; }
      .Home-testimonials-caleb-quote {
        vertical-align: middle;
        display: table-cell;
        margin-left: 50px;
        clear: both;
        width: 30%;
        font-size: 3vw;
        font-style: italic; }
        .Home-testimonials-caleb-quote .subtext {
          font-size: 2vw; }
